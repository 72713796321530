<template>
  <div>
    <absence-justified-day-legend :withTitle="false" v-if="isAbsenceJustified" class="p-4" />
    <absence-unjustified-day-legend :withTitle="false" v-else-if="isAbsenceUnjustified" />
    <absence-confirm-waiting-day-legend :withTitle="false" v-else-if="isAbsenceConfirmWaiting" />

    <vacations-need-meeting-day-legend :withTitle="false" v-else-if="isVacationsNeedReunion" />
    <vacation-confirm-waiting-day-legend :withTitle="false" v-else-if="isVacationsConfirmWaiting" />
    <vacations-approved-day-legend :withTitle="false" v-else-if="isVacationsApproved" />

    <off-day-legend :withTitle="false" v-else-if="isDayOff" />
    <sick-day-legend :withTitle="false" v-else-if="isDaySick" />
    <hour-planned-legend
      :class="classes"
      :withTitle="false"
      v-if="isWorking && !isNone"
      :planningBarStyle="employeePlanningStyle"
    />
  </div>
</template>

<script>
import { Cell } from "@/components/table";
import {
  TYPE_DAY_PLAN_ABSENCE_JUSTIFIED,
  TYPE_DAY_PLAN_ABSENCE_UNJUSTIFIED,
  TYPE_DAY_PLAN_ABSENCE_CONFIRM_WAITING,
  TYPE_DAY_PLAN_VACATIONS_CONFIRM_WAITING,
  TYPE_DAY_PLAN_VACATIONS_APPROVED,
  TYPE_DAY_PLAN_VACATIONS_NEED_REUNION,
  TYPE_DAY_PLAN_DAYOFF,
  TYPE_DAY_PLAN_DAYSICK,
  TYPE_DAY_PLAN_WORKING,
} from "@/pages/responsible/planing/data";
import {
  AbsenceConfirmWaitingDayLegend,
  AbsenceUnjustifiedDayLegend,
  AbsenceJustifiedDayLegend,
  OffDayLegend,
  SickDayLegend,
  VacationConfirmWaitingDayLegend,
  VacationsApprovedDayLegend,
  VacationsNeedMeetingDayLegend,
  HourPlannedLegend,
} from "@/pages/components/day-legend";

const fullHour = "full-hour";
const noneHour = "none";
const firstHalfHour = "first-half-hour";
const secondHalfHour = "second-half-hour";

export default {
  components: {
    AbsenceConfirmWaitingDayLegend,
    AbsenceUnjustifiedDayLegend,
    AbsenceJustifiedDayLegend,
    OffDayLegend,
    SickDayLegend,
    VacationConfirmWaitingDayLegend,
    VacationsApprovedDayLegend,
    VacationsNeedMeetingDayLegend,
    HourPlannedLegend,
  },
  props: {
    cellData: { type: Cell, required: true },
  },
  computed: {
    isAbsenceJustified() {
      return this.cellData.getValue == TYPE_DAY_PLAN_ABSENCE_JUSTIFIED;
    },
    isAbsenceUnjustified() {
      return this.cellData.getValue == TYPE_DAY_PLAN_ABSENCE_UNJUSTIFIED;
    },
    isAbsenceConfirmWaiting() {
      return this.cellData.getValue == TYPE_DAY_PLAN_ABSENCE_CONFIRM_WAITING;
    },
    isVacationsConfirmWaiting() {
      return this.cellData.getValue == TYPE_DAY_PLAN_VACATIONS_CONFIRM_WAITING;
    },
    isVacationsApproved() {
      return this.cellData.getValue == TYPE_DAY_PLAN_VACATIONS_APPROVED;
    },
    isVacationsNeedReunion() {
      return this.cellData.getValue == TYPE_DAY_PLAN_VACATIONS_NEED_REUNION;
    },
    isDayOff() {
      return this.cellData.getValue == TYPE_DAY_PLAN_DAYOFF;
    },
    isDaySick() {
      return this.cellData.getValue == TYPE_DAY_PLAN_DAYSICK;
    },
    isWorking() {
      return this.cellData.getValue == TYPE_DAY_PLAN_WORKING;
    },
    approvedHour() {
      return this.cellData.getByPathRaw.dayApprovedHours;
    },
    typeHour() {
      //full hour
      if (this.currentHour.defined == 1) {
        return fullHour;
      }

      //half of hour
      if (this.currentHour.defined == 0.3) {
        return this.$isset(this.nextHour) && this.nextHour.defined > 0
          ? secondHalfHour
          : firstHalfHour;
      }

      return noneHour;
    },
    meta() {
      return this.cellData.getColumn.meta;
    },
    hours() {
      return this.cellData.getRaw.plan.hours;
    },
    employeePlanningStyle() {
      return this.$isset(this.cellData.getRaw.employee.roleColor)
        ? { "background-color": `${this.cellData.getRaw.employee.roleColor}` }
        : {};
    },
    currentHour() {
      return this.hours.find((x) => this.$isset(x.hour) && x.hour.getHours() == this.meta.hour);
    },
    nextHour() {
      return this.hours.find((x) => this.$isset(x.hour) && x.hour.getHours() == this.meta.hour + 1);
    },
    previousHour() {
      return this.hours.find((x) => this.$isset(x.hour) && x.hour.getHours() == this.meta.hour - 1);
    },
    isFullHour() {
      return this.typeHour == fullHour;
    },
    isFirstHalfHour() {
      return this.typeHour == firstHalfHour;
    },
    isSeconfHalfHour() {
      return this.typeHour == secondHalfHour;
    },
    isNone() {
      return this.typeHour == noneHour;
    },
    classes() {
      let classes = {
        "w-50": this.isFirstHalfHour || this.isSeconfHalfHour,
        "planning-round-left":
          this.isSeconfHalfHour ||
          (this.isFullHour && this.$isset(this.previousHour) && this.previousHour.defined == 0),
        "planning-round-right":
          this.isFirstHalfHour ||
          (this.isFullHour && this.$isset(this.nextHour) && this.nextHour.defined == 0),
        "float-right": this.isSeconfHalfHour,
        "float-left": this.isFirstHalfHour,
      };

      return classes;
    },
  },
};
</script>

<style lang="scss">
.planning-round-left > hr {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.planning-round-right > hr {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
